import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Table } from 'react-bootstrap'
import config from '../../../config'
import moment from 'moment';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css';
// import { DateRangePicker  } from 'react-date-range';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceArea,
  ResponsiveContainer
} from 'recharts'


const initialData = [
  { batchid: 2, total: 1, Up: 4.11, Down: 100, CreatedOn: '2025-02-14 08:26:02.423' },
  { batchid: 3, total: 2, Up: 2.39, Down: 120, CreatedOn: '2025-02-14 08:27:47.857' },
];

const getAxisYDomain = (from, to, ref, offset) => {
  const refData = initialData.slice(from - 1, to);
  let [bottom, top] = [refData[0][ref], refData[0][ref]];
  refData.forEach((d) => {
    if (d[ref] > top) top = d[ref];
    if (d[ref] < bottom) bottom = d[ref];
  });
  return [(bottom | 0) - offset, (top | 0) + offset];
};

function WebsiteLogs() {
  const [websiteLogs, setWebsiteLogs] = useState([])
  const [upCount, setUpCount] = useState(0)
  const [downCount, setDownCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  
  const [data, setData] = useState(initialData);
  const [refAreaLeft, setRefAreaLeft] = useState('');
  const [refAreaRight, setRefAreaRight] = useState('');
  const today = new Date().toISOString().split('T')[0];
  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);
  const dataAPI = async () => {
    try {
      const response = await axios.get(config.apiUrl + '/getlogs')

      // console.log("API Response:", response.data);
      // const upCount = response.data.upCount
      // const downCount = response.data.downCount

      // Transform API response into a proper array format
      // const websiteLogsData = [
      //   ...response.data.upWebsites.map(site => ({
      //     website: site,
      //     upTime: 'Yes',
      //     downTime: 'No'
      //   })),
      //   ...response.data.downWebsites.map(site => ({
      //     website: site,
      //     upTime: 'No',
      //     downTime: 'Yes'
      //   }))
      // ]

      setWebsiteLogs(response.data.data)
      setTotalCount(response.data.data.length)
      // setUpCount(response.data.data.filter((itm) => itm.Up === 'Yes').length)
      // setDownCount(response.data.data.filter((itm) => itm.Down === 'Yes').length)
      // console.log(upCount, downCount)
      // setChartData([{ name: 'websites', Up: upCount, Down: downCount }])

    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  useEffect(() => {
    // setFromDate(new Date());
    // setToDate(new Date());
    dataAPI()
  }, [])

  const fetchData = async (fromDate, toDate) => {
    try {
      const response = await axios.post(config.apiUrl + '/CronStatusByDate', {fromDate, toDate}); // Replace with your API URL
      // console.log(response)
      const dt = response.data.data.map((itm) => {
        itm.CreatedOn = moment(itm.CreatedOn).format('lll');
        return itm
      })

      setData(dt);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // if(fromDate && toDate) {
      let interval;
      fetchData(fromDate, toDate); // Initial fetch
      interval = setInterval(() => fetchData(fromDate, toDate), 10000);
      
      return () => clearInterval(interval); // Cleanup on unmount
    // }
  }, [fromDate, toDate]);


  async function checkStatus() {
    try {
      const response = await axios.get(config.apiUrl + '/website')

      setTimeout(() => {
        dataAPI();
      }, 10000);
      // setWebsiteLogs(response.data.data)

    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  console.log(fromDate)
  return (
    <div className="container mt-4 pb-5" style={{'maxWidth' : '1400px'}}>
      {/* <div className="custom mt-5">
        
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Up" fill="green" name="Up Websites" />
            <Bar dataKey="Down" fill="red" name="Down Websites" />
          </BarChart>
        </ResponsiveContainer>
      </div> */}

      <div className='d-flex mt-5 pt-5 container align-items-left  gap-2'>
        <div className='d-flex gap-2 '>
          <span class="badge badge-pill badge-primary p-2 g-col-6 fs-5">Total: {totalCount} </span>
          <span class="badge badge-pill badge-success p-2 g-col-6 fs-5">Up: {data[data.length - 1].Up}</span>
          <span class="badge badge-pill badge-danger p-2 g-col-6 fs-5">Down: {data[data.length - 1].Down}</span>
        </div>
        <div className='d-flex container align-items-left gap-2 justify-content-end'>
          <div>
              <label for="fromDate" class="form-label">From Date:</label>
              <input type='date' id='fromDate' value={fromDate} onChange={(e) => {
                setFromDate(e.target.value)}} />
              {/* <DatePicker className="form-control" selected={fromDate} onChange={(date) => setFromDate(date)}/> */}
          </div>
          <div>
              <label for="toDate" class="form-label">To Date:</label>
              <input type='date' id='toDate' value={toDate} onChange={(e) => setToDate(e.target.value)} />

              {/* <DatePicker className="form-control" selected={toDate} onChange={(date) => setToDate(date)}/> */}
          </div>
        </div>
      </div>
      

      
      <div className='mt-4 pt-3'>
      <h3>Website Uptime vs Downtime</h3>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          width={800}
          height={400}
          data={data}
          onMouseDown={(e) => setRefAreaLeft(e.activeLabel)}
          onMouseMove={(e) => refAreaLeft && setRefAreaRight(e.activeLabel)}
          // onMouseUp={zoom}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis allowDataOverflow dataKey="CreatedOn" />
          <YAxis />
          <Tooltip />
          <Line type="natural" dataKey="Up" stroke="#8884d8" animationDuration={300} />
          <Line type="natural" dataKey="Down" stroke="#FF0000" animationDuration={300} />
          {/* {refAreaLeft && refAreaRight ? (
            <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
          ) : null} */}
        </LineChart>
      </ResponsiveContainer>
      </div>
      <br/><br/>
      <div className="d-flex justify-content-between">
        <h2 className="mb-4">Website Status Logs</h2>
        {/* <button className="btn btn-primary" onClick={() => checkStatus()}>Chech Status</button> */}
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Website</th>
            <th>Redireted To</th>
            <th>LastChecked</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {websiteLogs.map((site, index) => (
            <tr key={index}>
              <td>
                <a
                  href={`${site.Website}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {site.Website}
                </a>
              </td>
              <td>
              <a
                  href={`${site.RedirectUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {site.RedirectUrl}
                </a>
              </td>
              <td className="text-success">{site.LastChecked}</td>
              <td>{site.StatusTitle}</td>
            </tr>
          ))}
          {/* <tr>
            <td className='text-primary'>
              <a>Total Count: {totalCount}</a>
            </td>
            <td></td>
            <td className="text-success">Up Count: {upCount}</td>
            <td className="text-danger">Down Count: {downCount}</td>
          </tr> */}
        </tbody>
      </Table>
    </div>
  )
}

export default WebsiteLogs